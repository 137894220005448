import { dew as _commandsDew } from "./commands";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TimeSeriesAggregationType = exports.TimeSeriesEncoding = exports.TimeSeriesDuplicatePolicies = exports.default = void 0;

  var commands_1 = _commandsDew();

  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return commands_1.default;
    }
  });

  var commands_2 = _commandsDew();

  Object.defineProperty(exports, "TimeSeriesDuplicatePolicies", {
    enumerable: true,
    get: function () {
      return commands_2.TimeSeriesDuplicatePolicies;
    }
  });
  Object.defineProperty(exports, "TimeSeriesEncoding", {
    enumerable: true,
    get: function () {
      return commands_2.TimeSeriesEncoding;
    }
  });
  Object.defineProperty(exports, "TimeSeriesAggregationType", {
    enumerable: true,
    get: function () {
      return commands_2.TimeSeriesAggregationType;
    }
  });
  return exports;
}