import { dew as _Dew } from ".";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.IS_READ_ONLY = exports.FIRST_KEY_INDEX = void 0;

  const _1 = _Dew();

  exports.FIRST_KEY_INDEX = 1;
  exports.IS_READ_ONLY = true;

  function transformArguments(key) {
    return ["TS.GET", key];
  }

  exports.transformArguments = transformArguments;

  function transformReply(reply) {
    if (reply.length === 0) return null;
    return (0, _1.transformSampleReply)(reply);
  }

  exports.transformReply = transformReply;
  return exports;
}