import { dew as _Dew } from ".";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.IS_READ_ONLY = void 0;

  const _1 = _Dew();

  exports.IS_READ_ONLY = true;

  function transformArguments(filter, options) {
    const args = ["TS.MGET"];
    (0, _1.pushWithLabelsArgument)(args, options?.SELECTED_LABELS);
    (0, _1.pushFilterArgument)(args, filter);
    return args;
  }

  exports.transformArguments = transformArguments;
  ;

  function transformReply(reply) {
    return reply.map(([key, labels, sample]) => ({
      key,
      labels: (0, _1.transformLablesReply)(labels),
      sample: (0, _1.transformSampleReply)(sample)
    }));
  }

  exports.transformReply = transformReply;
  return exports;
}